export default defineNuxtRouteMiddleware(async (to, _from) => {
  const user = useAuth().data.value

  if (!user) return

  if (user.type !== 'guest') return

  if (to.path.includes('/checkout')) return

  // Reset the order shipping address
  useOrder().order.value.delivery = null

  // Sign out the guest user
  await useAuth().signOut({
    redirect: false,
  })
})
