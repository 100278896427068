import JagofonFactory from '../jagofon-factory'
import type {
  ApiSlideImageKey,
  ApiSliderResponse,
  Slide,
} from '~/types/api/slider'

const SlideImageOrder: ApiSlideImageKey[] = [
  'wide.jpg',
  'desktop.jpg',
  'tablet.jpg',
  'mobile.jpg',
  'origin.jpg',
  'wide.jpeg',
  'desktop.jpeg',
  'tablet.jpeg',
  'mobile.jpeg',
  'origin.jpeg',
  'wide.png',
  'desktop.png',
  'tablet.png',
  'mobile.png',
  'origin.png',
]

export default class SliderRepository extends JagofonFactory {
  // TODO: Move to content module
  async getSlides() {
    const { locale } = useI18n()
    const {
      data: { value: slides },
    } = await useAsyncData(
      `slides:${locale.value}`,
      () => this.call<ApiSliderResponse>('GET', '/slider'),
      {
        transform: (response) =>
          response.data.map((slide) => {
            const sortedImages = SlideImageOrder.map((key) => [
              key,
              slide.images[key],
            ]).filter(([, value]) => value)

            const images = sortedImages.flatMap(([key, url]) => ({
              key,
              normal: url,
              retina: url.replace('@1x', '@2x'),
            }))

            const voucher = /\?voucher=(.*)/g.exec(slide.url)

            return {
              name: slide.name,
              url: slide.url,
              images,
              voucher: voucher ? voucher[1] : undefined,
            } as Slide
          }),
        default: () => [] as Slide[],
        getCachedData: (key, nuxt): Slide[] =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )

    return slides
  }
}
