import JagofonFactory from '../jagofon-factory'
import type {
  ApiSearchProduct,
  Product,
  QuickSearchResponse,
  QuickSearchResult,
  SearchProduct,
  SearchQuery,
  SearchResponse,
  SearchResult,
  SearchSuggestion,
  Tag,
} from '~/types/api/search'
import type { JagofonApiResponse, Warranty } from '~/types/api'
import type {
  ApiOngoingSale,
  ApiUpcomingSale,
  OngoingSale,
  UpcomingSale,
} from '~/types/api/promotion'

export default class ProductRepository extends JagofonFactory {
  getFeaturedProducts() {
    return this.call<JagofonApiResponse<ApiSearchProduct[]>>(
      'GET',
      '/home/popular-children',
    )
      .then((response) => response.data.map(useProduct().formatProduct))
      .catch((error) => {
        useSentry().captureException(error)
        throw new Error(error)
      })
  }

  getInventory() {
    return this.call<JagofonApiResponse<ApiSearchProduct[]>>(
      'GET',
      '/product/inventory',
    )
      .then((response) => response.data.map(useProduct().formatProduct))
      .catch((error) => {
        useSentry().captureException(error)
        throw new Error(error)
      })
  }

  getQuickSearchResults(query: string) {
    return this.call<QuickSearchResponse>(
      'GET',
      '/product/quick-search',
      undefined,
      {
        query: { query },
      },
    ).then(
      (response) =>
        ({
          suggestions: response.suggestions.map<SearchSuggestion>(
            (suggestion) => ({
              ...suggestion,
              url: `/${suggestion.type}/${suggestion.slug}`,
            }),
          ),
          items: response.data.map<SearchProduct>(useProduct().formatProduct),
        }) as QuickSearchResult,
    )
  }

  getSearchResults(query: SearchQuery) {
    return this.call<SearchResponse>('POST', '/product/search', query).then(
      (response) =>
        ({
          facets: response.facets,
          perPage: response.per_page,
          total: response.total,
          items: response.data.map<SearchProduct>(useProduct().formatProduct),
        }) as SearchResult,
    )
  }

  getProductById(id: number) {
    return this.call<JagofonApiResponse<ApiSearchProduct>>(
      'GET',
      `/product/children/${id}`,
    ).then((response) => useProduct().formatProduct(response.data) as Product)
  }

  getSimilarProducts(id: number) {
    return this.call<JagofonApiResponse<ApiSearchProduct[]>>(
      'GET',
      `/product/children/${id}/similar`,
    ).then((response) => response.data.map(useProduct().formatProduct))
  }

  getWarranties() {
    return this.call<JagofonApiResponse<Warranty[]>>('GET', '/warranty').then(
      (response) => response.data,
    )
  }

  getOngoingSales() {
    return this.call<JagofonApiResponse<ApiOngoingSale>>('GET', '/sale').then(
      ({ data }) =>
        ({
          endAt: new Date(data.end_at),
          groups: data.groups.map((group) => ({
            nameEn: group.name_en,
            nameId: group.name_id,
            products: group.products.map(useProduct().formatProduct),
          })),
        }) as OngoingSale,
    )
  }

  getUpcomingSales() {
    return this.call<JagofonApiResponse<ApiUpcomingSale>>(
      'GET',
      '/sale/upcoming',
    ).then(
      ({ data }) =>
        ({
          startAt: new Date(data.start_at),
          endAt: new Date(data.end_at),
          groups: data.groups.map((group) => ({
            nameEn: group.name_en,
            nameId: group.name_id,
            products: group.products.map(useProduct().formatProduct),
          })),
        }) as UpcomingSale,
    )
  }

  getSignalStatuses() {
    return this.call<JagofonApiResponse<Tag[]>>('GET', '/signal-status').then(
      (response) => response.data,
    )
  }
}
