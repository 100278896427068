import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import logout_45guest_45global from "/usr/src/app/middleware/logout-guest.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  logout_45guest_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "apply-voucher": () => import("/usr/src/app/middleware/apply-voucher.ts"),
  "checkout-items": () => import("/usr/src/app/middleware/checkout-items.ts"),
  "checkout-shipping-valid": () => import("/usr/src/app/middleware/checkout-shipping-valid.ts"),
  auth: () => import("/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}