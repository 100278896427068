import { captureEvent } from '@sentry/vue'
import { $fetch } from 'ofetch'
import AuthRepository from '~/repository/modules/auth'
import ContactRepository from '~/repository/modules/contact'
import ContentRepository from '~/repository/modules/content'
import NavigationRepository from '~/repository/modules/navigation'
import OrderRepository from '~/repository/modules/order'
import PaymentRepository from '~/repository/modules/payment'
import ProductRepository from '~/repository/modules/product'
import ResaleRepository from '~/repository/modules/resale'
import SliderRepository from '~/repository/modules/slider'
import SocialRepository from '~/repository/modules/social'
import UserRepository from '~/repository/modules/user'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const jagofonApi = $fetch.create({
    baseURL: import.meta.server
      ? config.jagofonApiBaseUrl
      : config.public.jagofonApiBaseUrl,
    onRequest({ options }) {
      options.headers ||= {}

      const locale = useNuxtApp().$i18n.locale.value
      options.headers.lang = locale
      options.headers['Accept-Language'] = locale

      if (useAuth().token.value) {
        options.headers.Authorization = useAuth().token.value
      }
    },
    onResponseError(context) {
      const user = useAuth().data.value
      captureEvent({
        level: 'warning',
        message: 'API Error',
        user: {
          id: user?.id,
          email: user?.email,
        },
        extra: {
          url: context.request.toString(),
          options: context.options,
          response: context.response._data,
        },
      })
    },
  })

  const api = {
    social: new SocialRepository(jagofonApi),
    slider: new SliderRepository(jagofonApi),
    navigation: new NavigationRepository(jagofonApi),
    product: new ProductRepository(jagofonApi),
    content: new ContentRepository(jagofonApi),
    contact: new ContactRepository(jagofonApi),
    payment: new PaymentRepository(jagofonApi),
    auth: new AuthRepository(jagofonApi),
    user: new UserRepository(jagofonApi),
    order: new OrderRepository(jagofonApi),
    resale: new ResaleRepository(jagofonApi),
  }

  return {
    provide: {
      api,
    },
  }
})
