import type { OngoingSale, UpcomingSale } from '~/types/api/promotion'

type Partner = 'gojek' | 'grab' | 'kredivo' | 'gopay-later'

export default function usePromo() {
  const partner = useState<Partner | undefined>('partner', () => undefined)

  const voucher = useOrder().voucher

  const isGojek = computed(() => voucher.value?.code === 'JAGOJEK')

  const isGrab = computed(() => voucher.value?.code === 'GRABBENEFITS')

  const isKredivo = computed(
    () => voucher.value?.code === 'JAGOFONKREDIVOAUG24',
  )

  const ongoingSale = useAsyncData(
    'ongoing-sale',
    () => useNuxtApp().$api.product.getOngoingSales(),
    {
      default: () =>
        ({
          endAt: new Date(0),
          groups: [],
        }) as OngoingSale,
      getCachedData: (key, nuxt): OngoingSale =>
        nuxt.payload.data[key] || nuxt.static.data[key],
      dedupe: 'defer',
    },
  )

  const upcomingSale = useAsyncData(
    'upcoming-sale',
    () => useNuxtApp().$api.product.getUpcomingSales(),
    {
      default: () =>
        ({
          startAt: new Date(0),
          endAt: new Date(0),
          groups: [],
        }) as UpcomingSale,
      getCachedData: (key, nuxt): UpcomingSale =>
        nuxt.payload.data[key] || nuxt.static.data[key],
      dedupe: 'defer',
    },
  )

  const getSale = async () => {
    if ((await ongoingSale).data.value?.groups.length) {
      return 'flash-sale'
    }

    return null
  }

  return {
    isGojek,
    isGrab,
    isKredivo,
    ongoingSale,
    upcomingSale,
    getSale,
    partner,
  }
}
