import JagofonFactory from '../jagofon-factory'
import type {
  ContactForm,
  CorporateContactForm,
  JagofonApiResponse,
} from '~/types/api'

export default class ContactRepository extends JagofonFactory {
  postContact(data: ContactForm) {
    return this.call<JagofonApiResponse<ContactForm>>('POST', '/inquiry', data)
  }

  postCorporate(data: CorporateContactForm) {
    return this.call<JagofonApiResponse<CorporateContactForm>>(
      'POST',
      '/corporate',
      {
        name: data.name,
        email: data.email,
        phone_number: data.phoneNumber,
        company_name: data.companyName,
        company_number: data.companyNumber,
        message: data.message,
      },
    )
  }
}
